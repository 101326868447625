import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { PartnerPageQuery } from '../types/graphql-types';
import Ctx from '../types/page-context';
import Box from '@pagerland/common/src/components/Box';
import Container from '@pagerland/common/src/components/Container';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import ThemeMdx from "../containers/ThemeMdx";

const WrapperProps = {
  pt: {
    _: 56,
    md: 64,
    lg: 192,
  },
  pb: {
    _: 56,
    md: 64,
    lg: 96,
  },
};
const ContainerProps = {
  textAlign: 'left'
};

type Props = PageProps<PartnerPageQuery, Ctx>;
const PartnerTemplate: React.FC<Props> = ({data, pageContext, location}) => {
  const partner = (
    <ThemeMdx title={"Customer: " + data.info.frontmatter.title} data={data} location={location.pathname} pageContext={pageContext} >
      <Box {...WrapperProps}>
        <Container {...ContainerProps}>
          <MDXRenderer frontmatter={data.info.frontmatter}>{data.info.body}</MDXRenderer>
        </Container>
      </Box>
    </ThemeMdx>
  );

  return partner;
};

export default PartnerTemplate;

export const pageQuery = graphql`
  query PartnerPage($id: String!, $idFrontmatter: String!, $lang: String!) {
    info: mdx(id: { eq: $id }) {
      ...PartnerPageFragment
    }
    otherLanguages: allMdx(filter: {frontmatter: {id: {eq: $idFrontmatter}, lang: {ne: $lang}}}) {
      edges {
        node {
          frontmatter {
            lang
            slug
          }
        }
      }
    }
  }
`;

export const partnerPageFragment = graphql`
  fragment PartnerPageFragment on Mdx {
    frontmatter {
      slug
      title
      lang
    }
    body
  }
`;